import LeftArrowDecal from '@/components/LeftArrowDecal';

import MagnifyingGlassIcon from 'svgs/redesign/howSearch.svg';
import ProfessionalIcon from 'svgs/redesign/howPerson.svg';
import HomeIcon from 'svgs/redesign/howHand.svg';

import * as styles from './index.module.scss';

const SMSFormContent = () => (
  <div className={styles['how-it-works-container']}>
    <LeftArrowDecal />
    <div className="container">
      <h2>How It Works</h2>
      <div className={styles['panels-container']}>
        <div className={styles['panel']}>
          <div className={styles['step']}>01</div>
          <div className={styles['icon-container']}>
            <MagnifyingGlassIcon />
          </div>
          <label>Tell us what service you need</label>
        </div>
        <div className={styles['divider-container']}>
          <div />
        </div>
        <div className={styles['divider']}></div>
        <div className={styles['panel']}>
          <div className={styles['step']}>02</div>
          <div className={styles['icon-container']}>
            <ProfessionalIcon />
          </div>
          <label>Let us match you to a local professional</label>
        </div>
        <div className={styles['divider-container']}>
          <div />
        </div>
        <div className={styles['panel']}>
          <div className={styles['step']}>03</div>
          <div className={styles['icon-container']}>
            <HomeIcon />
          </div>
          <label>Get the help you need, commitment-free</label>
        </div>
      </div>
    </div>
  </div>
);

export default SMSFormContent;
